import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import './App.css';

// Import the banner image
import bannerImage from './banner.jpg';
import { Accordion } from 'flowbite-react';

function App() {
  const searchParams = new URL(document?.location?.toString()).searchParams;
  const merchantId = searchParams?.get('merchantId');
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      await axios
        .post(
          'https://api.mewt.in/backend/v1/authentication/get-google-token',
          {
            code: codeResponse.code,
            merchantId: merchantId,
          }
        )
        .then(response => {
          console.log(response);
          window.location.href = 'https://superpe.in';
        });
    },
    onError: errorResponse => console.log(errorResponse),
    scope: 'https://www.googleapis.com/auth/gmail.readonly'
  });


  return (
    <div className='App h-auto justify-center flex bg-[#190b14]' >
      {/* Banner image */}


<div className="max-w-sm border rounded-lg shadow bg-gray-800 border-gray-700 my-auto">
    <div>
        <img className="rounded-t-lg" src={bannerImage} alt="" />
    </div>
    <div className="p-5">
        <div className='flex flex-row items-center justify-center my-8'>
          <div className='aspect-square p-2 bg-[#190b14] rounded-md items-center justify-center flex mr-4'>
            <img
                src="https://feassetsnew.blob.core.windows.net/superappimages/SuperPe_logo.PNG"
                alt="Superpe icon"
                className="w-12 h-12"
              />
          </div>
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-white">SuperPe Verification ✦</h5>
        </div>
        <div className='flex flex-row my-4 items-center bg-gray-900 p-2 rounded-md justify-between'>
<div className=' mx-4'>
<svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 0 640 512" fill='#266ef1'><path className="fa-secondary" opacity=".4" d="M449.2 297.8c-19.8 27.1-39.7 54.4-49.2 86.2H240c-9.6-31.9-29.5-59.1-49.2-86.2c-5.2-7.1-10.4-14.2-15.4-21.4C155.6 247.9 144 213.3 144 176C144 78.8 222.8 0 320 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4zM240 176c0-44.2 35.8-80 80-80c8.8 0 16-7.2 16-16s-7.2-16-16-16c-61.9 0-112 50.1-112 112c0 8.8 7.2 16 16 16s16-7.2 16-16z"/><path className="fa-primary" d="M69.3 4l48 32c11 7.4 14 22.3 6.7 33.3s-22.3 14-33.3 6.7l-48-32c-11-7.4-14-22.3-6.7-33.3S58.3-3.3 69.3 4zM597.3 44l-48 32c-11 7.4-25.9 4.4-33.3-6.7s-4.4-25.9 6.7-33.3l48-32c11-7.4 25.9-4.4 33.3 6.7s4.4 25.9-6.7 33.3zM24 160H88c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24zm528 0h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552c-13.3 0-24-10.7-24-24s10.7-24 24-24zM117.3 332l-48 32c-11 7.4-25.9 4.4-33.3-6.7s-4.4-25.9 6.7-33.3l48-32c11-7.4 25.9-4.4 33.3 6.7s4.4 25.9-6.7 33.3zm432-39.9l48 32c11 7.4 14 22.3 6.7 33.3s-22.3 14-33.3 6.7l-48-32c-11-7.4-14-22.3-6.7-33.3s22.3-14 33.3-6.7zM400 432c0 44.2-35.8 80-80 80s-80-35.8-80-80V384H400v48z"/></svg>
</div>

<p className="font-normal text-gray-400 text-left ">Here we're establishing your card ownership by verifying your email.</p></div>

        <button type="button" className="w-full items-center justify-center text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm mx-auto px-5 py-4 bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-blue-800 flex flex-row"
              onClick={() => {
                googleLogin();
              }}
            >
              {/* SVG for Google login button */}
              <svg
                className='mx-4 -ml-1 w-5 h-5'
                aria-hidden='true'
                focusable='false'
                data-prefix='fab'
                data-icon='google'
                role='img'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 488 512'
              >
                <path
                  fill='currentColor'
                  d='M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z'
                ></path>
              </svg>
              Sign up with Google<div></div>
            </button>

    </div>

<div className='p-5'>
    <Accordion>
      <Accordion.Panel>
        <Accordion.Title>What is SuperPe?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          SuperPe is an application that allows you to securely pay & collect your products from various registered sellers across the country.
          </p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title>What's the purpose for email syncing?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          By syncing your Emails, SuperPe app aims to establish the ownership of your card used on the app. The benefit of opting to connect your Gmail account is that your transactions, once verified, will go through instantly.
          </p>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title>Can I opt-out?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          You can anytime choose to de-link your Gmail account with the application at any time and/ or you can delete your information by writing to support@superpe.in
          </p>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
</div>

<footer className=" rounded-lg shadow bg-gray-800">
    <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center flex flex-row items-center justify-center text-center">
    <ul className="flex flex-wrap items-center mt-3 text-sm font-medium  text-gray-400 sm:mt-0 justify-center text-center">
        <li>
            <a href="https://superpe.in/about" className="hover:underline me-4 md:me-6">About</a>
        </li>
        <li>
            <a href="https://superpe.in/privacy" className="hover:underline me-4 md:me-6">Privacy Policy</a>
        </li>
        <li>
            <a href="https://superpe.in/terms" className="hover:underline me-4 md:me-6">Terms</a>
        </li>
        <li>
            <a href="https://superpe.in/" className="hover:underline cursor-pointer">Contact</a>
        </li>
    </ul>
    </div>
</footer>

  </div>

</div>
  );
}

export default App;
